<template>
  <vx-card>
    <div class="vx-row mb-6">
      <vs-button
        class="ml-4 mt-2"
        color="danger"
        icon-pack="feather"
        icon="icon-arrow-left"
        @click="handleBack()"
      >Back
      </vs-button>
    </div>

    <div class="flex md:flex-row">
      <div class="w-full pr-6 md:basis-1/2">
        <div class="mb-6 vx-row">
          <div class="flex items-center w-full vx-col sm:w-1/6">
            <span>B-ED ID</span>
          </div>
          <div class="w-full vx-col sm:w-5/6">
            <vs-input class="w-full bg-grey-light" :value="code" disabled readonly></vs-input>
          </div>
        </div>

        <div class="mb-6 vx-row">
          <div class="flex items-center w-full vx-col sm:w-1/6">
            <span>Warehouse Code</span>
          </div>
          <div class="w-full vx-col sm:w-5/6">
            <vs-input class="w-full bg-grey-light" :value="warehouseCode" disabled readonly></vs-input>
          </div>
        </div>

        <div class="mb-6 vx-row">
          <div class="flex items-center w-full vx-col sm:w-1/6">
            <span>Counting Date</span>
          </div>
          <div class="w-full vx-col sm:w-5/6">
            <vs-input class="w-full bg-grey-light" :value="countingDate" disabled readonly></vs-input>
          </div>
        </div>

        <div class="mb-6 vx-row">
          <div class="flex items-center w-full vx-col sm:w-1/6">
            <span>Note</span>
          </div>
          <div class="w-full vx-col sm:w-5/6">
            <vs-textarea class="w-full bg-grey-light" :value="note" disabled readonly/>
          </div>
        </div>

      </div>

      <div class="w-full md:basis-1/2">
        <div class="mb-6 vx-row">
          <vs-divider style="width: 50%; margin-left: 10%">
            List Attachment
          </vs-divider>
          <div
            class="vx-row mb-3 mt-6 w-4/4"
            style="width: 50%; margin-left: 10%"
          >
            <table class="vs-table vs-table--tbody-table">
              <template>
                <tr
                  class="tr-values vs-table--tr tr-table-state-null selected"
                  v-bind:key="tr.NameFile"
                  v-for="tr in fileAttachment"
                >
                  <td class="td vs-table--td">{{ tr.FileName }}</td>
                  <td class="td vs-table--td">
                    <vx-tooltip text="Show" v-if="tr.Path != ''">
                      <vs-button
                        type="line"
                        icon-pack="feather"
                        icon="icon-eye"
                        @click.stop="handleShowAttachment(tr.FileUrl)"
                      />
                    </vx-tooltip>
                  </td>

                </tr>
              </template>
            </table>
          </div>
        </div>
      </div>
    </div>
    <br/>
    <div class="vx-row mb-6">
      <table width="100%" class="m-3 vs-table vs-table--tbody-table">
        <thead class="vs-table--thead">
        <tr>
          <th style="width: 8.5%;">SKU</th>
          <th style="width: 12.5%;">SKU Description</th>
          <th style="width: 12.5%;">Warehouse Area</th>
          <th style="width: 6%;">Type</th>
          <th style="width: 8.5%;">Batch External</th>
          <th style="width: 6%;">ED</th>
          <th style="width: 6%;">New Batch External</th>
          <th style="width: 6%;">New ED</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(line, index) in lines" :key="index" style="padding-bottom: 5px">
          <td class="td vs-table--td" style="padding: 5px">
            <vs-input :value="line.SkuCode" disabled readonly
                      class="w-full bg-grey-light"></vs-input>
          </td>
          <td class="td vs-table--td" style="padding: 5px">
            <vs-input :value="line.ItemName" disabled readonly
                      class="w-full bg-grey-light"></vs-input>
          </td>
          <td class="td vs-table--td" style="padding: 5px">
            <vs-input :value="line.WarehouseAreaCode + ' - ' + line.WarehouseAreaName" disabled readonly
                      class="w-full bg-grey-light"></vs-input>
          </td>
          <td class="td vs-table--td" style="padding: 5px">
            <vs-input :value="line.AreaTypeTypes" disabled readonly
                      class="w-full bg-grey-light"></vs-input>
          </td>
          <td class="td vs-table--td" style="padding: 5px">
            <vs-input :value="line.BatchExternal" disabled readonly
                      class="w-full bg-grey-light"></vs-input>
          </td>
          <td class="td vs-table--td" style="padding: 5px">
            <vs-input :value="formatDate(line.ExpiredDate)" disabled readonly class="w-full bg-grey-light"></vs-input>
          </td>
          <td class="td vs-table--td" style="padding: 5px">
            <vs-input :value="line.NewBatch" disabled readonly
                      class="w-full bg-grey-light"></vs-input>
          </td>
          <td class="td vs-table--td" style="padding: 5px">
            <vs-input :value="formatDate(line.NewExpiredDate)" disabled readonly class="w-full bg-grey-light"></vs-input>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </vx-card>
</template>

<script>
import Attachment from "@/views/stockOpname/batch-ed/components/Attachment.vue";
import moment from "moment";

export default {
  components: {Attachment},
  data() {
    return {
      id: this.$route.query.id ? parseInt(this.$route.query.id) : null,
      code: null,
      warehouseCode: null,
      countingDate: null,
      note: null,
      fileAttachment: [],
      lines: [],
    }
  },
  methods: {
    handleBack() {
      this.$vs.loading();
      this.$router.go(-1);
      this.$vs.loading.close();
    },
    getData() {
      this.$vs.loading();
      this.$http.get("/api/wms/v1/batch-ed/detail/" + this.id).then((resp) => {
        if (resp.code === 200) {
          this.code = resp.data.batch_ed.Code;
          this.warehouseCode = resp.data.batch_ed.WarehouseCode + " - " + resp.data.batch_ed.WarehouseName;
          this.countingDate = moment(resp.data.batch_ed.CountingDate).format('YYYY-MM-DD');
          this.note = resp.data.batch_ed.Note;
          this.fileAttachment = resp.data.batch_ed_attach;
          this.lines = resp.data.batch_ed_line;
        } else {
          this.$vs.notify({
            title: "Error",
            text: resp.message,
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check"
          });
        }
        this.$vs.loading.close();
      });
    },
    handleShowAttachment(file) {
      window.open(file, "_blank");
    },
    formatDate(date) {
      return date ? moment(date).format('YYYY-MM-DD') : '';
    },
  },
  mounted() {
    if (this.id) {
      this.getData();
    }
  },
};
</script>
